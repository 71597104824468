import { local } from 'd3';
import {getUserAgent, getDeviceType, isMobile, isDesktop, isIos, isIphone, isAndroid} from './device.js';

/**
 * Underscore's debounce:
 * http://underscorejs.org/#debounce
 */
const debounce = function(func, wait, immediate) {
  let result;
  let timeout = null;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
    }
    return result;
  };
};

const classNamePrefixes = {
  env: 'g-page',
  breakpoint: 'g-viewport'
};

const breakpoints = {
  xxsmall: 320,
  xsmall: 480,
  small: 600,
  medium: 740,
  large: 1024,
  xlarge: 1150,
  xxlarge: 1440
};

// const ratioLimits = {
// 	landscape: 1.26,
// 	portrait: 1.15
// };

const userAgent = getUserAgent();

let debug = false;
export const setDebug = value => {
  debug = value;
};

const html = document.documentElement;
const body = document.body;

// Environment
// -----------------------
const isApp = () => !!(
  window.location.href.indexOf('app.html') > 0 ||
  window.location.search.indexOf('nytapp') > -1 || // sometimes this query param is present
  userAgent.match(/nyt[-_]?(?:ios|android)/i) || // usually the user agent is set
  (userAgent.match(/android/i) && window.__HYBRID__) // on hybrid articles in android, the user agent and qs is missing
);

const getEnvironment = () => {
  if (location.hostname.indexOf('localhost') > -1) {
    return 'development';
  }

  // handle runway.nyt.net and runway.stg.nyt.net 
  if (location.hostname === 'preview.nyt.net' || location.hostname.indexOf('runway') > -1) {
    return 'preview';
  }

  return 'production';
};

const getState = () => ({
  isApp: isApp(),
  isIos: isIos(),
  isAndroid: isAndroid(),
  isIphone: isIphone(),
  isMobile: isMobile(),
  isDesktop: isDesktop(),
  isLandscape: isLandscape(),
  isPortrait: isPortrait(),
  isWideScreen: isWideScreen(),
  isSmallScreen: isSmallScreen(),
  isLargeScreen: isLargeScreen(),
  isHighDensity: isHighDensity(),
  isRetina: isRetina()
});

/**
 * Return an array of breakpoint names, width and active status
 *
 * [{name: 'xxsmall', width: '320', active: true}, {}]
 */
const getBreakpoints = () => {
  const vw = getViewport().width;

  return Object.keys(breakpoints).map(name => {
    const width = breakpoints[name];

    return {
      width,
      name,
      active: vw >= width
    };
  });
};

/**
 * Maintain classes on the html element that reflect the
 * current state of all the isSomething functions
 *
 * For example: isMobile() -> html.g-env-ismobile
 * @param {*} debug
 */
const prepEnvironment = (_debug) => {
  _debug = _debug || debug;

  // update cached viewport values
  setViewport();

  getBreakpoints().forEach((breakpoint) => {
    // g-viewport-xxsmall, etc
    const className = `${classNamePrefixes.breakpoint}-${breakpoint.name}`;

    if (breakpoint.active) {
      html.classList.add(className);
    } else {
      html.classList.remove(className);
    }

    if (_debug) {
      console.log(breakpoint.name, breakpoint.active);
    }
  });

  // run all test and record their true/false state.
  // ex: isMobile() -> g-env-ismobile
  const testResults = getState();
  Object.keys(testResults).forEach((fnName) => {
    // isMobile to g-env-ismobile
    const className = `${classNamePrefixes.env}-${fnName.toLowerCase()}`;
    const result = testResults[fnName];

    // add or remove html class based on result of test
    if (result) {
      html.classList.add(className);
    } else {
      html.classList.remove(className);
    }

    if (_debug) {
      console.log(fnName, result);
    }
  });
};

// Resolution
// -----------------------

const pixelRatio = () => window.devicePixelRatio || 1.0;

const isHighDensity = () => (
  (window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) ||
  (pixelRatio() > 1.3)
);

// http://stackoverflow.com/questions/19689715/what-is-the-best-way-to-detect-retina-support-on-a-device-using-javascript
const isRetina = () => ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches ||
window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) ||
(pixelRatio() >= 2)) && /(iPad|iPhone|iPod)/g.test(userAgent);


// Viewport, Aspect & Orientation
// -----------------------

let cachedViewport;

const setViewport = () => {
  const width = Math.max(html.clientWidth, window.innerWidth);
  const height = Math.max(html.clientHeight, window.innerHeight);
  const aspectRatio = width / height;
  cachedViewport = { width, height, aspectRatio };
};

const getViewport = () => {
  if (!cachedViewport) {
    setViewport();
  }

  return cachedViewport;
};

const getViewportPosition = () => {
  const { height, width } = getViewport();
  const top = window.pageYOffset || html.scrollTop || body.scrollTop;
  const left = window.pageXOffset || html.scrollLeft || body.scrollLeft;
  const bottom = height + top;
  const right = width + left;

  return { top, bottom, left, right };
};

const getAspectRatio = () => getViewport().aspectRatio;
const isLandscape = () => getAspectRatio() > 1;
const isPortrait = () => !isLandscape();
const isWideScreen = () => getAspectRatio() > (breakpoints.xxlarge / 1029);


// Size
// -----------------------

const isSmallScreen = () => getViewport().width <= breakpoints.medium;
const isLargeScreen = () => getViewport().width >= breakpoints.xlarge;



// START APP
// ---------------------


html.classList.add(`${classNamePrefixes.env}-${getEnvironment()}`);


const debouncedPrepEnvironment = debounce(prepEnvironment, 250);
window.addEventListener('resize', () => { debouncedPrepEnvironment(); });
prepEnvironment();


export {
  // TODO do all these need to be exported?
  html,
  body,

  isApp,
  isIos,
  isAndroid,
  isIphone,
  isMobile,
  isDesktop,
  isLandscape,
  isPortrait,
  isWideScreen,
  isSmallScreen,
  isLargeScreen,
  isHighDensity,
  isRetina,

  getEnvironment,
  getState,
  getBreakpoints,
  getUserAgent,
  getDeviceType,
  getViewport,
  getViewportPosition,
  getAspectRatio,

  setViewport,
  pixelRatio,
  prepEnvironment
};
