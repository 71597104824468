// Freebird modules like images.js and videos.js are now
// imported automatically if they're used in the Google doc.
// If you need to *disable* a module like scripts/freebird/images.js,
// just delete all the code from it.
// import VIDEOS from './scripts/freebird/videos.js';
// import {scrollingGraphics} from './scripts/freebird/scrollinggraphic.js';
// import {scrollingSlides} from './scripts/freebird/scrollingslides.js';
import './scripts/lib/page.js';

// NODE MODULES
// import _ from 'lodash';
// import $ from 'jquery';
// import * as d3 from 'd3';
// import * as d3 from 'd3-jetpack/d3-index.js';
// import { stateAbbrev } from '@nytg/graphics-toolkit';

// SCRIPTS
// import example from './scripts/example.js';

// TEMPLATES
// import templates from '../build/templates.js';

// WEBGL
// import createREGL from 'regl';
// import fragShader from './scripts/shaders/triangle.frag.glsl';

// ADS
// import './scripts/ads.js';

// WRITE YOUR JS HERE:



// console.clear();

// console.log('initial page state:', page.getState());


// text balance headline and subheds
// (consider doing this with CSS instead, which provides a significantly better user experience)
// import balanceText from './scripts/lib/balance-text.js';
// balanceText('.interactive-heading, .g-subhed');
if (window.innerWidth < 740) {
    setCustomProperty();
}
function setCustomProperty() {
    // Checking for css variables support
    if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
        const root = document.documentElement;
        root.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
}